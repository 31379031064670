.rbc-agenda-table thead tr th:nth-child(2) {
  display: none;
}

.rbc-time-content {
  display: none;
}

.rbc-time-header {
  flex: 1 0 auto;
}

.rbc-agenda-event-cell {
  cursor: pointer;
}

.rbc-agenda-time-cell {
  display: none;
}

.user-calendar-day-view .rbc-event {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-bottom: 1px solid #e0e0e0;
}

.user-calendar-day-view .rbc-label.rbc-time-header-gutter {
  display: none;
}
